import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export function homesiteSliders() {
  document.addEventListener( 'DOMContentLoaded', function () {

    if(document.querySelector('.sectionBannerHomesiteSlider__slider')) {
      var homesiteSlider = new Splide( '.sectionBannerHomesiteSlider__slider', {
      type       : 'loop',
      perPage : 1,
      autoplay : true,
      interval : 4000,
      pagination : true,
      arrows     : false,
      } ).mount();
    }

    if(document.querySelector('.sectionBannerSlider__slider')) {
      var homesiteSlider2 = new Splide( '.sectionBannerSlider__slider', {
      type       : 'loop',
      perPage : 1,
      autoplay : true,
      interval : 4000,
      pagination : true,
      arrows     : false,
      } ).mount();
    }
  });
}

